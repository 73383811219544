import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AssetManagementState {
  pageNumber : number;
  search:string;
  pageSize:number;
  loader:boolean;
  pageOrder:string;
  filter:AssetManagementFilter
}
export interface AssetManagementFilter{
   assetType:string[];
}
export const initialState: AssetManagementState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    loader:false,
    pageOrder:"createdAt dsc",
    filter:{
      assetType:[]
    }
   
};
interface AssetFilter{
  name:string;
  value : string[];
}
const assetManagementSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<AssetManagementState>)=>{
        state.pageNumber =  action.payload.pageNumber
    },
    searchAsset : (state,action:PayloadAction<AssetManagementState>)=>{
      state.pageNumber = 1;
      state.search =  action.payload.search
  },
  setPageSize: (state, action: PayloadAction<AssetManagementState>) => {
    state.pageSize = action.payload.pageSize;
  },
  setCategoryFilter : (state,action:PayloadAction<AssetFilter>)=>{
    state.pageNumber = 1;
    state.filter.assetType = [...action.payload.value];
    
  },
  setPageOrder:(state,action)=>{
    state.pageOrder = action.payload;
},
  setLoader  : (state,action:PayloadAction<boolean>)=>{
      state.loader = action.payload;
  },
  clearAssetState : ()=>{
     return initialState
   }
  },  
});
const { actions, reducer } = assetManagementSlice;

export const { setPageNumber,setLoader,setPageOrder ,searchAsset,setPageSize,clearAssetState,setCategoryFilter} = actions;
export default reducer;
