import { p } from 'msw/lib/glossary-dc3fd077';
import React, { forwardRef } from 'react'

interface InputInterface {
    children?: React.ReactNode;
    name: string;
    type?: string;
    className?: string;
    value?: string;
    formik: any;
    id?: string;
    disabled?: boolean;
    required?: boolean;
    onChange?: Function;
    onBlur?: Function;
    placeholder?: string;
    autocomplete?: string;
    onFocus?:Function;

}
export const FormikInput = (props: InputInterface) => {
    return (
        <input
            type="text"
            className="form-control"
            data-testid={props.name}
            onFocus={props.formik.onFocus}
            onBlur = {props.formik.handleBlur}
            {...props}
            onChange={(e) => {
                props.formik.handleChange(e);
                if (props.name === "email" || props.name === "businessAccountEmail" || props.name === "technicalLeadEmail") {
                    props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9a-zA-Z-@.]+/img, ''));
                    return
                }
                else if (props.name === "code") {
                    props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9a-zA-Z\u00C0-\u00FF]+/img, ''));
                    return
                }
                else if (props.name === "name") {
                    props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9a-zA-Z\u00C0-\u00FF,. '<>="?]+/img, ''));
                    return
                }
                else if (props.name === "url") {
                    props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9a-zA-Z,/:.\u00C0-\u00FF'<>="?/\s/!?/(/)_-]+/img, ''));
                    return
                }
                else if (props.name === "title") {
                    props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9a-zA-Z\u00C0-\u00FF'<>=",./\s/!?/(/)_-]+/img, ''));
                    return
                } else if (props.name === "technicalLeadContact" || props.name === "businessAccountContact") {
                    props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9(/)-]+/img, ''));
                    return
                } 
                props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9a-zA-Z\u00C0-\u00FF'<>=",./\s/!?/(/)_-]+/img, ''));
            }}
            //onBlur={props.formik.handleBlur}
        />
    )
}
export const FormikTextArea = (props: any) => {
    return (
        <textarea
            className="form-control"
            data-testid={props.name}
            {...props}
            onChange={(e) => {
                props.formik.handleChange(e)
                props.formik.setFieldValue(props.name, e.target.value.replace(/[^0-9a-zA-Z,.'<>="?{}\u00C0-\u00FF/\s/!?/(/)_-]+/img, ''));

            }}
            onBlur={props.formik.handleBlur}

        ></textarea>
    )
}
export const TextAreaWithHtmlInjection = (props: any) => {
    return (
        <textarea
            className="form-control"
            {...props}
            onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9a-zA-Z/(/)'<>="/\s-?.,!@$%&#\u00C0-\u00FF]+/img, '')
                if (props.onChange && typeof props.onChange === "function") {
                    props.onChange(e);
                }
            }}


        ></textarea>
    )
}
export const InputWithHTMLInjection = forwardRef((props: any, ref: any) => {
    return (<input
        type="text"
        ref={ref}
        {...props}
        onChange={(e) => {
            e.target.value = e.target.value.replace(/[^0-9a-zA-Z'<>="/(/)/\u00C0-\u00FF\s-]+/img, '')
            if (props.onChange && typeof props.onChange === "function") {
                props.onChange(e);
            }
        }}
    />)
})

export const InputBox = (props: any) => {
    return (<input
        type="text"
        {...props}
        onChange={(e) => {
            e.target.value = e.target.value.replace(/[^0-9a-zA-Z'<>="/(/)\u00C0-\u00FF/\s-]+/img, '');
            if (props.onChange && typeof props.onChange === "function") {
                props.onChange(e);
            }
        }

        }

    />)
}