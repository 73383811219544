import axios from 'axios';
import config from 'config';
const headers = {
    'Content-Type': 'application/json'
}
export let source = axios.CancelToken.source();
export const axiosAccounts = axios.create({
    baseURL: config[config.env].apiEndpoints.account,
    headers,
});
export const axiosContent = axios.create({
    baseURL: config[config.env].apiEndpoints.content,
    headers,
});

export const axiosAggregate = axios.create({
    baseURL: config[config.env].apiEndpoints.aggregate,
    headers,
});

export const axiosAsset = axios.create({
    baseURL: config[config.env].apiEndpoints.asset
});
export const axiosVendor = axios.create({
    baseURL: config[config.env].apiEndpoints.vendor
});  
export const axiosReport = axios.create({
    baseURL: config[config.env].apiEndpoints.report
});     
export const axiosThirdparty = axios.create({
    baseURL: config[config.env].apiEndpoints.thirdparty
}); 
export const axiosLogoutApi = axios.create({
    baseURL: config[config.env].apiEndpoints.account,
    headers,
});


axiosAggregate.interceptors.request.use((request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem("carePlanAccessToken")}`;
    return request;
})
axiosVendor.interceptors.request.use((request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem("carePlanAccessToken")}`;
    return request;
})   
axiosReport.interceptors.request.use((request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem("carePlanAccessToken")}`;
    return request;
})                                    
 
axiosContent.interceptors.request.use((request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem("carePlanAccessToken")}`;
    return request;
})

axiosAccounts.interceptors.request.use((request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem("carePlanAccessToken")}`;
    return request;                                                                                                  
})                        
                                                                                  
axiosAsset.interceptors.request.use((request) => {
    request.headers["Authorization"] = `Bearer ${localStorage.getItem("carePlanAccessToken")}`;
    return request;
})
axiosLogoutApi.interceptors.request.use((request) => {
    return request;
})
axiosAsset.interceptors.response.use((response) => {
    return response;
})